import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"

//export default () => <div>Just Testing!</div>

export default () => (    
    <div style={{ color: `purple` }}>
    <Link to="/">Home</Link> | 
    <Link to="/about/">About</Link> | 
    <Link to="/contact/">Contact</Link> | 
    <Link to="/about-css-modules/">About-CSS-Modules</Link>
    <Header headerText="About Gatsby" /> 
    <h1>Hello Gatsby!</h1>
    <p>What a world.</p>
    <img src="https://source.unsplash.com/random/400x200" alt="" />
  </div>
  
  )
